<template>
  <div id="app">

    <!-- Nav Menu -->

    <!-- Key Full Path enables to use the same page template with diffrent data and will refresh -->
    <router-view :key="$route.fullPath"/> 

    <!-- Footer Menu -->
    
  </div>
</template>

<script>
export default {
  
}
</script>


<style>
.fButton{
  background-color: #00629b;
  border-color: #00629b;
}
.fButton:hover, .fButton:active, .fButton:focus{
  background-color: #fff!important;
  color: #00629b!important;
  border-color: #00629b!important;
  box-shadow: none!important;
}
.reversefButton:hover, .reversefButton:active, .reversefButton:focus{
  background-color: #00629b;
  border-color: #00629b;
}
.reversefButton{
  background-color: #fff!important;
  color: #00629b!important;
  border-color: #00629b!important;
  box-shadow: none!important;
}
.row{
  margin: 0;
}
</style>
