<template>
  <div>
    <div class="home">
      <template v-for="(block, index) in pageData[0].gblocks">
        <div class="block" :key="index" v-html="block.innerContent[0]"></div>
      </template>
    </div>
    <div class="pCards" v-if="$route.name === 'Home'">
      <Post />
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Post from "@/components/cards.vue";
export default {
  name: "Home",
  components: { Post },
  data: function() {
    return {
      pageData: [],
    };
  },
  computed: {
    pageURL() {
      if (this.$route.path === "/") {
        var url = "/wp-json/wp/v2/pages?slug=home";
      } else {
        url = "/wp-json/wp/v2/posts?slug=" + this.$route.params.id;
      }
      return url;
    },
  },
  mounted() {
    apiCall
      .wpInfo(this.pageURL)
      .then(({ data }) => {
        this.pageData = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.home {
  position: relative;
}

::v-deep .block > h1 {
  position: absolute;
  z-index: 10;
  color: #fff;
  width: 100%;
  text-align: center;
  top: 50%;
}
::v-deep .wp-block-image {
  background-color: #000;
}
::v-deep .wp-block-image > img {
  opacity: 0.8;
  width: 100%;
}
.pCards {
  padding: 50px 0;
}
@media only screen and (max-width: 450px) {
::v-deep .block > h1 {
  top: 30%;
}
}
</style>
