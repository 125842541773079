<template>
  <div class="align">
    <h3 class="fTitle">Filter</h3>
    <b-row>
      <b-col lg='12' class="cats" v-for="(cat, index) in filterCats" :key="index">
        <b-form-checkbox
          :id="cat.name"
          v-model="select"
          :name="cat.name"
          :value="cat.id"
          unchecked-value=""
          @input="update"
        >
          <span class="catLabel">{{ cat.name }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col lg='12'>
        <b-button class="fButton" @click="reset">Reset</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "filter",
  data() {
    return {
      cats: [],
      select: "",
    };
  },
  methods: {
    update() {
      var find = this.select;
      this.$emit("update", { filter: find });
    },
    reset() {
      this.select = "";
      this.$emit("reset", { set: "reset" });
    },
  },
  computed: {
    filterCats(pCats) {
      pCats = this.cats.filter(
        (cat) => cat.slug !== "uncategorized" && cat.slug !== "uncategorised"
      );
      return pCats;
    },
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/categories")
      .then(({ data }) => {
        this.cats = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.align {
  padding-left: 80px;
}
.fTitle {
  padding: 0 0 25px;
}
.cats {
  padding-bottom: 25px;
}
.catLabel {
  text-transform: capitalize;
}
.fButton {
  background-color: #00629b;
  border-color: #00629b;
}
.fButton:hover,
.fButton:active,
.fButton:focus {
  background-color: #fff !important;
  color: #00629b !important;
  border-color: #00629b !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 1280px) {
  .align {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 990px) {
  .align {
    padding-left: 50px;
    padding-bottom: 25px;
  }
  .cats {
    padding: 10px 10px 0 10px;
  }
  .fButton {
    height: fit-content;
  }
}
</style>
