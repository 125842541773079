<template>
  <div>
  <h2 class="cHead"> Search for the best Eastbourne offers </h2>
    <b-row>
      <b-col lg="2" md="12" cols="12">
        <Filt @update="setCase" @reset="setReset" />
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col lg="4" md="6" cols="12" v-for="(post, index) in posts" :key="index">
            <b-card
              :title="post.acf.title"
              :img-src="post.acf.thumb"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
              <b-card-text>
                {{ post.acf.blurb }}
              </b-card-text>

              <b-button class='reversefButton' :to="'/' + post.slug" variant="primary">More</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Filt from "@/components/filt.vue";
export default {
  name: "cards",
  components: {
    Filt,
  },
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    setCase(payload) {
      console.log(payload);
      apiCall
        .wpInfo("/wp-json/wp/v2/posts?categories=" + payload.filter)
        .then(({ data }) => {
          this.posts = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    setReset() {
      apiCall
        .wpInfo("/wp-json/wp/v2/posts?per_page=99")
        .then(({ data }) => {
          this.posts = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/posts?per_page=99")
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style css scoped>
.cHead{
  text-align: center;
  padding-bottom: 50px;
}
::v-deep .card{
  background-color: #00629b;
  color: #fff;
  border: 1px solid #000;
}
::v-deep .card-img-top{
  background-color: #fff;
}
@media only screen and (max-width: 990px) {
  ::v-deep .card{
        margin: 0 15px 15px;
  }
}
</style>