import axios from "axios";

class apiCall {
  wpInfo(json) {
      var api_url = "https://hearted-flanges.000webhostapp.com/"

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(json);
  }
}

export default new apiCall();
